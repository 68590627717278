import * as React from "react";
import { ButtonLink } from "../components/Button";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = () => (
  <Layout headerProps={{ fixed: true }}>
    <Seo title="404: Not found" />
    <div className="flex items-center justify-center w-full h-screen text-center text-white">
      <div>
        <h1 className="font-roboto text-9xl mb-0">404</h1>
        <p className="mb-8">Page not found!</p>
        <ButtonLink to="/">Go home</ButtonLink>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
